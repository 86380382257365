
// Vue reactivity
import { defineComponent } from 'vue';
import { computed, ref } from 'vue';

// icons
import { arrowBack, arrowBackOutline, arrowForward, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonLabel,
        IonContent, IonGrid, IonRow, IonCol, IonSpinner,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, 
        modalController, alertController, } from '@ionic/vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import DesignService from '@/services/DesignService';

import { Browser } from '@capacitor/core';
import config from '@/config';

export default defineComponent({
  name: 'DesignPreQuestionModal',
  props: ["designId"],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonLabel,
                IonContent, IonGrid, IonRow, IonCol, IonSpinner,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const closeModal = async () => { await modalController.dismiss() };
    const goToDesignDetailPage = async () => {
      if (props.designId) router.replace(`/designs/${props.designId}`);
      else {
        //router.replace('/new-design?showHelpModal=1');
        router.replace('/new-design');
      }
      await closeModal();
    }

    const SECTIONS = {
      withPets: 1,
      numOfPets: 2,
      petCategories: 3,
      petBreeds: 4,
      petColors: 5,
      bottles: 6,
    };
    const MAX_NUM_OF_PETS = 2;

    // 1. declare state variables (ref to make them reactive)
    const currSection = ref(SECTIONS.withPets);
    const targetPetNumber = ref(1);
    const targetPetCategory = ref("狗"); // default is dog
    const targetPetBreed = ref("");

    const petCategories = computed(() => store.getters.petCategories);
    const groupedPets = computed(() => store.getters.groupedPets);
    const bottles = computed(() => store.state.bottles);
    const loadingData = computed(() => store.state.loadingPublicData);
    const settings = computed(() => store.state.settings);
    const defaultPetDemoNotice = "呢張相只係寵物品種示範圖，實物係會按照你主子既相片定製架。";

    const designId = route.params.id; // design ID (for editing existing designs)
    const design = designId ? computed(() => store.getters.getDesignById(designId)) :
                                  computed(() => store.state.newDesign);

    const setDesignWithPets = (withPets: any) => {
      if (designId) { // update design in DB
        DesignService.updateDesign({ designId, updatedObj: { 'with_pets': withPets } });
      }
      store.commit('updateDesign', { designId, updatedObj: { 'withPets': withPets } });

      // if without pets then go to bottle selection directly
      currSection.value = withPets ? SECTIONS.numOfPets : SECTIONS.bottles;
    }
    const setDesignBottle = (bottle: any) => {
      if (designId) { // update design in DB
        DesignService.updateDesign({ designId, updatedObj: { 'bottle_id': bottle.id } });
      }
      store.commit('updateDesign', { designId, updatedObj: { 'bottle': bottle } });
      goToDesignDetailPage();
    }
    const setDesignPet = async (pet: any) => {
      store.commit('updateDesign', { designId, updatedObj: { [`pet${targetPetNumber.value}`]: {
        designId, petTypeId: pet.id, petTypeName: pet.name, photoLink: pet.photoLink, id: `pet${targetPetNumber.value}`
      } } });
      currSection.value = SECTIONS.numOfPets;

      const alert = await alertController.create({
        header: '放心',
        message: settings.value["寵物示範圖備註"] || defaultPetDemoNotice,
        buttons: ['我知道喇！'],
      });
      await alert.present();
    }
    const onSelectPetBreed = (breed: any, pets: any) => {
      if (pets.length == 1) {
        setDesignPet(pets[0]);
      } else {
        targetPetBreed.value = breed;
        currSection.value = SECTIONS.petColors;
      }
    }
    const clearDesignPet = (targetPetNumber: any) => {
      store.commit('updateDesign', { designId, updatedObj: { [`pet${targetPetNumber}`]: null } });
    }
    const setTargetPetNumber = async (petNumber: any) => {
      if (petNumber > MAX_NUM_OF_PETS) {
        await Browser.open({ url: config.enquiryLink, toolbarColor: config.primaryColor }); // 聯絡客服
      } else {
        targetPetNumber.value = petNumber;
        //currSection.value = SECTIONS.petCategories;
        currSection.value = SECTIONS.petBreeds; // TMP: only limit to dog type selection
      }
    }
    const setTargetPetCategory = (category: any) => {
      targetPetCategory.value = category;
      currSection.value = SECTIONS.petBreeds;
    }
    const getCurrNumOfPets = () => {
      const { pet1, pet2, pet3, } = design.value;
      return [pet1, pet2, pet3].filter(p => !!p).length;
    }
    const calculateNumOfPets = () => {
      if (designId) { // update design in DB
        store.dispatch('updateDBDesignPreQuestionAnswers', { design: design.value });
      }
      store.commit('updateDesign', { designId, updatedObj: { 'numOfPets': getCurrNumOfPets() } });
      //setDesignBottle(bottles.value.find(b => b.id == '細瓶')); // TMP: for competitions
      setDesignBottle(bottles.value.find(b => b.id == '中瓶')); // TMP: for competitions
      //currSection.value = SECTIONS.bottles;
    }

    setDesignWithPets(true);
    if (design.value.pet1 == null) setTargetPetNumber(1); // TMP: for design competition only

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, arrowForward, close,

      // variables
      SECTIONS, loadingData, settings, defaultPetDemoNotice,
      design,
      currSection,
      targetPetNumber, targetPetCategory, targetPetBreed,
      bottles,
      petCategories,
      groupedPets,

      // methods
      t,
      setDesignWithPets, setDesignBottle,
      setDesignPet, clearDesignPet,
      setTargetPetNumber, calculateNumOfPets, getCurrNumOfPets,
      setTargetPetCategory, onSelectPetBreed,
      closeModal, goToDesignDetailPage,
    }
  },
})
